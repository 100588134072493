"use client";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";

export default function HeaderBanner({ slides }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef(null);

  const startSlideShow = (intervalTime) => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(nextSlide, intervalTime);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => {
      return prevSlide >= slides.length - 1 ? 0 : prevSlide + 1;
    });
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    startSlideShow(11000);
  };

  useEffect(() => {
    startSlideShow(7000);

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div className="home-two-header-banner">
      <div className="image-container">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
            style={{ display: index === currentSlide ? "block" : "none" }}
          >
            <Image
              src={slide.image.data.attributes.url}
              width={1920}
              height={1080}
              priority={index === 0}
              alt={
                slide.image.data.attributes.alternativeText ||
                `FactoryCat Industrial Floor Scrubber Banner ${slide.id}`
              }
              sizes="(max-width: 768px) 100vw, 1920px"
              loading={index === 0 ? "eager" : "lazy"}
            />
            <div className={`content-wrapper ${slide.position}`}>
              <div className="content">
                <h2>{slide.title}</h2>
                <p>{slide.description}</p>
                <div className="button-wrapper">
                  {slide.button_one && (
                    <Link
                      className="cta-btn-1"
                      href={slide.button_one.location}
                    >
                      {slide.button_one.text}
                    </Link>
                  )}
                  {slide.button_two && (
                    <Link
                      className="cta-btn-1"
                      href={slide.button_two.location}
                    >
                      {slide.button_two.text}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="button-container">
        {slides.slice(0, 5).map((_, index) => (
          <button
            key={index}
            className={currentSlide === index ? "active" : ""}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
}
