"use client";
import React from "react";
import Image from "next/image";
export default function Gallery(props) {
  const images = props.homePageGallery.images.data.slice(0, 6);
  return (
    <div className="home-gallery-section">
      <div className="home-gallery-wrapper">
        {images.map((image) => {
          return (
            <div className="gallery-section_image-container" key={image.id}>
              <Image
                src={image.attributes.url}
                alt={
                  `${image.attributes.url}` ||
                  `FactoryCat Industrial Floor Scrubber`
                }
                width={1920}
                height={1080}
              />
            </div>
          );
        })}
      </div>

    </div>
  );
}
