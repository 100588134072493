"use client";
import Image from "next/image";
import React from "react";
import Link from "next/link";
export default function FactoryCatStory(props) {
  const aboutData = props.aboutInfo;
  return (
    <div className="nopad-page dark">
      <div className="story-wrapper">
        <div className="story-text-wrapper">
          <h2>{aboutData.attributes.home_page_title}</h2>
          <p>{aboutData.attributes.home_page_text}</p>
          <Link href="/about">FactoryCat: Our Story</Link>
        </div>
        <div className="image-wrapper">
          <Image
            src={aboutData.attributes.home_page_main_image.data.attributes.url}
            alt={
              aboutData.attributes.home_page_main_image.data.attributes
                .alternativeText || `Factory Cat Floor Scrubber`
            }
            width={1920}
            height={1080}
          />
        </div>
      </div>
    </div>
  );
}
