"use client";
import React from "react";
import { AiOutlineExport } from "react-icons/ai";
import { useRouter } from "next/navigation";
import Link from "next/link";
export default function CardsSection(props) {
  const cardData = props.cardData;
  const router = useRouter();
  return (
    <div className="card-section">
      {cardData.map((card) => (
        <Link
          className="card"
          key={card.id}
          href={card.location}
        >
          <div className="card-section-header">
            <h2>{card.title}</h2>
            <AiOutlineExport />
          </div>
          <p>{card.description}</p>
        </Link>
      ))}
    </div>
  );
}
